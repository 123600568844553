// store/user.js
import Cookies from "js-cookie";
import { clearFulfilmentData } from "~/composables/clearFulfilmentData";
import { useClientsStore } from "~/stores/clients"; // Import insights store

export const useUserStore = defineStore("user", {
  state: () => ({
    access_token: null, // Sanctum access token
    bearer_token: null, // Passport bearer token
    _roles: [],

    // account settings
    _user: [],

    // companies
    _companies: [],
    _acceptedCompanyInvites: [],
  }),
  getters: {
    user: (state) => state._user,
    clients: (state) => state._companies,
    roles: (state) => state._roles,
    acceptedCompanyInvites: (state) => state._acceptedCompanyInvites,
  },
  actions: {
    async login(email, password) {
      const { $clientAccount } = useNuxtApp();
      // make an API call to login the user with an email address and password
      await $clientAccount.auth
        .login({
          email,
          password,
        })
        .then(async (response) => {
          this.access_token = response.data.access_token;
          Cookies.set("access_token", this.access_token, { expires: 365 });
        })
        .catch((error) => {
          return { status: "error", message: "login failed" };
        });
    },
    async me() {
      const { $clientAccount } = useNuxtApp();
      const response = await $clientAccount.user.me();

      if (response.status === 200) {
        this._user = response.data.data;

        // set user in $auth
        const auth = useAuth();
        await auth.setUser(response.data);

        // set users language
        const { setLocale } = useI18n();
        await setLocale(response.data.data.language);
        Cookies.set("language", response.data.data.language, { expires: 365 });

        // process user role
        this.assignRoles(response.data.data.roles);

        // process user company data
        const clientsStore = useClientsStore();
        await clientsStore.setClients(response.data.data.clients);

        await this.acceptedCompanies();
      }
    },
    // refresh access token
    async refresh() {
      const { $clientAccount } = useNuxtApp();
      if (
        Cookies.get("access_token") &&
        Cookies.get("access_token") !== "undefined"
      ) {
        const response = await $clientAccount.auth.refresh();

        // response failed
        if (response.status !== 200) {
          await this.logout();
          return false;
        }
        // response succeeded, set tokens
        else {
          this.access_token = response.data.access_token;
        }

        return response;
      }

      await this.logout();
      return false;
    },
    async logout() {
      const { $clientAccount } = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      return await $clientAccount.auth
        .logout()
        .then(() => {
          const clientsStore = useClientsStore();
          clientsStore.resetStore();
          clearFulfilmentData();
          window.location = runtimeConfig.public.accountsUrl + "/logout";
        })
        .catch((error) => {
          return { status: "error", message: "login failed" };
        });
    },
    assignRoles(roles) {
      if (roles.length > 0) {
        const userRoles = [];
        for (const role of roles) {
          userRoles.push(role.name);
        }
        this._roles = userRoles;
      }
    },
    async acceptedCompanies() {
      const auth = useAuth();
      const userId = auth.user.id;
      const { $clientAccount } = useNuxtApp();
      return await $clientAccount.users.invites
        .list(userId, {
          status: "accepted",
        })
        .then((response) => {
          let inviteData = response.data.invite;

          const acceptedCompanies = Array.isArray(inviteData)
            ? inviteData.map((invite) => ({
                id: invite.clients.id,
                name: invite.clients.name,
              }))
            : [
                {
                  id: inviteData.clients.id,
                  name: inviteData.clients.name,
                },
              ];

          this._acceptedCompanyInvites = acceptedCompanies;
          return acceptedCompanies;
        })
        .catch((error) => {
          return { status: "failed", message: error };
        });
    },
  },
});
