import { useInsightsStore } from "~/stores/insights";
import Cookies from "js-cookie";

export const clearFulfilmentData = () => {
  const insightsStore = useInsightsStore();

  // Reset all store properties that have cookies
  insightsStore.updateSelectedOMSShop([]);
  insightsStore.updateSelectedCMSShop({});
  insightsStore.updateSelectedDateRange([]);
  insightsStore.updateSelectedPeriod({});
  insightsStore.updateSelectedShopGroup([]);
  insightsStore.updateSelectedShopOwner([]);
  insightsStore.updateActiveCategory(null);

  // List all cookies to be removed
  const savedCookies = [
    // Filters cookies
    "orderFilters",
    "shipmentFilters",
    "purchaseOrdersFilters",
    "parcelFilters",
    "returnFilters",
    "inventoryFilters",
    "productFilters",

    // Store-related cookies
    "selectedCMSShop",
    "selectedOMSShop",
    "selectedDateRange",
    "selectedPeriod",
    "selectedShopGroup",
    "selectedShopOwner",
    "activeCategory",
  ];

  const cookieOptions = { path: "/", domain: window.location.hostname };

  savedCookies.forEach((cookieName) => {
    Cookies.remove(cookieName, cookieOptions);
  });
};
